import io from "socket.io-client";
import type { App } from "vue";

import type { ParentData } from "@/entities/parent";
import type { User } from "@/entities/user";

interface Options extends Partial<Pick<ParentData["payload"], "host" | "token" | "xOrigin">> {
  isNativePlatform: boolean;
  user: User;
}

const socket = io(import.meta.env.VITE_SERVER_HOST, {
  autoConnect: false,
  path: import.meta.env.VITE_SOCKET_PATH,
  reconnection: false,
  transports: ["websocket"],
});

export default {
  install: (_: App, options: Options) => {
    if (options.token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      socket.io.uri = undefined;
    }

    socket.io.opts.query = {
      ...(!options.token && { Authorization: options.user.token }),
      Language: options.user.lang,
      xorigin: options.xOrigin || new URL(import.meta.env.VITE_SERVER_HOST).host,
      ...(options.isNativePlatform && {
        isNative: true,
        platform: options.user.platform,
      }),
      cashier_type: "widget",
    };

    socket.connect();
  },
};

export { socket };
