import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import { transitions } from "@/shared/constants";
import { routes, routesV2 } from "@/shared/routing";

const initRouter = (routes: RouteRecordRaw[], withRouteAnimation = false) => {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.afterEach((to, from) => {
    if (!withRouteAnimation) {
      return;
    }

    const toDepth = to.meta?.depth ?? 0;
    const fromDepth = from.meta?.depth ?? 0;

    Object.assign(to.meta, {
      transition: to.meta.transition ?? (toDepth < fromDepth ? transitions.slideLeft : transitions.slideRight),
    });
  });

  return router;
};

export const router = initRouter(routes, true);
export const routerV2 = initRouter(routesV2);
