import "@/app/index.css";
import { pick } from "lodash";
import { storeToRefs } from "pinia";
import { createApp } from "vue";

import App from "@/app";
import { clickOutsideDirective, sanitizeHtmlDirective } from "@/app/directives";
import { useAppStore } from "@/app/model";
import { configPlugin, consolePlugin, datadogPlugin, i18n, i18nPlugin, socketPlugin } from "@/app/plugins";
import { router, routerV2, store } from "@/app/providers";
import { useParentStore } from "@/entities/parent";
import { useThemeStore } from "@/entities/theme";
import { useUserStore } from "@/entities/user";

const app = createApp(App);

app.directive("click-outside", clickOutsideDirective);
app.directive("sanitize-html", sanitizeHtmlDirective);

app.use(configPlugin);
app.use(store);

const { init, initStyles } = useAppStore();

const { parentData } = storeToRefs(useParentStore());

const { isLegacyDesign } = storeToRefs(useThemeStore());

const { isNativePlatform, user } = storeToRefs(useUserStore());

await init();
await initStyles();

app.use(datadogPlugin);
app.use(i18n);
app.use(i18nPlugin, { user: user.value });
app.use(socketPlugin, {
  ...pick(parentData.value, ["host", "token", "xOrigin"]),
  isNativePlatform: isNativePlatform.value,
  user: user.value,
});
app.use(consolePlugin);
app.use(isLegacyDesign.value ? router : routerV2);

app.mount("#app");
