import { loggerService } from "@/shared/services";

type Listener = "onGoBack" | "onSwipeBack";

type Payload =
  | AnalyticsEvent
  | CloseEvent
  | CopyEvent
  | CreateTransactionEvent
  | ErrorEvent
  | ExternalBrowserEvent
  | ExternalNavigationEvent
  | FullLoadedEvent
  | LoadedEvent
  | OpenRuleEvent
  | ShareEvent
  | TokenExpireEvent;

interface AnalyticsEvent {
  messageType: "onAnalyticsEvent";
  payload: {
    event: "send_deposit_events" | "send_withdrawal_events";
    event_cat: "deposit" | "withdrawal";
    event_name: string;
  } & Record<string, unknown>;
}

interface CloseEvent {
  messageType: "onClose";
}

interface CopyEvent {
  copiedMessage: string;
  messageType: "onCopyToBuffer";
}

interface CreateTransactionEvent {
  eventType: "deposit" | "withdrawal";
  messageType: "onCreateTransaction";
  payload: {
    eventId: number;
  };
}

interface ErrorEvent {
  message: string;
  messageType: "Error";
  status: number;
  url: string;
}

interface ExternalBrowserEvent {
  messageType: "openExtBrowser";
  url: string;
}

interface ExternalNavigationEvent {
  messageType: "extNavigation";
  uri: string;
}

interface FullLoadedEvent {
  messageType: "FullLoaded";
  version: string;
}

interface LoadedEvent {
  messageType: "Loaded";
  version: string;
}

interface OpenRuleEvent {
  messageType: "openRule";
  ruleId: number;
}

interface ShareEvent {
  messageType: "onShare";
  sharedMessage: string;
}

interface TokenExpireEvent {
  messageType: "onTokenExpired";
}

class BridgeService {
  attachEventListener(type: Listener, listener: EventListenerOrEventListenerObject) {
    window.addEventListener(type, listener);
  }

  detachEventListener(type: Listener, listener: EventListenerOrEventListenerObject) {
    window.removeEventListener(type, listener);
  }

  notify(payload: Payload) {
    const data = JSON.stringify(payload);

    loggerService.log(">>> Bridge.notify", payload);

    if ("webkit" in window && "messageHandlers" in window.webkit && "message" in window.webkit.messageHandlers) {
      window.webkit.messageHandlers.message.postMessage(data);
      return;
    }

    if (window.billingBridge) {
      window.billingBridge?.nativePostMessage?.(data);
      return;
    }

    const parentUrl = window.location !== parent.location ? document.referrer : document.location.href;
    parent.postMessage(data, parentUrl);
  }
}

export const bridgeService = new BridgeService();
