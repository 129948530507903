import { storeToRefs } from "pinia";

import type { Deposit } from "@/entities/deposit";
import { useUserStore } from "@/entities/user";
import type { Withdrawal } from "@/entities/withdrawal";
import { bridgeService } from "@/shared/services";

const convertDataToQuery = function convertDataToQuery(data: Record<string, unknown>, prefix?: string): string {
  const pairs: string[] = [];

  for (const [key, value] of Object.entries(data)) {
    let pair;
    const enkey = encodeURIComponent(key);

    if (typeof value === "object") {
      pair = convertDataToQuery(value as Record<string, unknown>, prefix ? prefix + "[" + enkey + "]" : enkey);
    } else {
      pair = (prefix ? prefix + "[" + enkey + "]" : enkey) + "=" + encodeURIComponent(value as string);
    }

    pairs.push(pair);
  }

  return pairs.join("&");
};

export const buildGetUrl = (source: string, data: Record<string, unknown>) => {
  const url = new URL(source);
  const query = Object.keys(data).length > 0 ? `?${convertDataToQuery(data)}` : "";
  return `${url.origin}${url.pathname}${query}${url.hash}`;
};

const buildPostUrl = (source: string, data: Record<string, unknown>) => {
  const url = new URL("/form", window.location.href);

  url.searchParams.append("action", source);
  url.searchParams.append("method", "POST");

  for (const [key, value] of Object.entries(data)) {
    url.searchParams.append(key, value as string);
  }

  return url.href;
};

export const openBrowser = (apiResponse: Deposit["apiResponse"] | Withdrawal["apiResponse"]) => {
  const { isNativePlatform } = storeToRefs(useUserStore());

  const url =
    apiResponse.method === "GET"
      ? buildGetUrl(apiResponse.source, apiResponse.data as Record<string, unknown>)
      : buildPostUrl(apiResponse.source, apiResponse.data as Record<string, unknown>);

  if (isNativePlatform.value) {
    bridgeService.notify({ messageType: "openExtBrowser", url });
    return;
  }

  window.open(url);
};
