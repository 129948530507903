import { datadogRum } from "@datadog/browser-rum";

export default {
  install: () => {
    if (!import.meta.env.PROD) {
      return;
    }

    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: import.meta.env.VITE_DATADOG_SITE,
      service: import.meta.env.VITE_DATADOG_SERVICE,
      env: import.meta.env.MODE,
      version: import.meta.env.VITE_BUILD_VERSION || "1.0.0",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  },
};
